import React from "react";
import { Layout, PortfolioNav, PortfolioHeader } from "../../components";
import locales from "../../constants";

const Colorex = ({ pageContext: { slug } }) => {
  const nav = {
    initialSlideIndex: 1,
  };
  const lang = "pl";
  return (
    <Layout
      seo={{
        title: "Colorex ABC",
        href: slug,
        lang: "pl",
      }}
      header={{
        background:
          "linear-gradient( 45deg, #22967c, #30af93, #2ABA9A, #30af93, #22967c )",
        icons: "#ff3c00",
        navClass: "colorex",
        ogImage: require("../../assets/img/portfolio/colorex_top_back.jpg"),
      }}
      langSwitch={{
        langKey: "en",
        langSlug: "/en/projects/colorex/",
      }}
    >
      <PortfolioHeader name="colorex" height="176" />
      <section className="container-fluid colorex_section_2" id="info">
        <div className="row">
          <div className="col-md-6">
            <div className="inner">
              <h1>Colorex ABC</h1>
              <ul>
                <li>Strona internetowa</li>
                <li>Kampania leadowa</li>
                <li>Banery reklamowe</li>
              </ul>
            </div>
          </div>
          <div className="col-md-6">
            <div className="inner">
              <p>
                {/* DMDmodular features advanced off-site building methods offering turn-key products in a fast–track
                modular and construction solution, which offers minimal disruption to the surrounding facilities
                operation. We guaranty high-end quality finishing product as well as up to 50% time saving due to
                production in a dry, factory controlled environment, and then transportation of the complete building
                parts to a fully prepared site, for a faster return on your investment. */}
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="container-fluid colorex_section_3">
        <div className="row row_1">
          <div className="col-md-6 col-image">
            <img
              src={require("../../assets/img/portfolio/colorex_screen.png")}
              alt=""
            />
          </div>
          <div className="col-md-6 text-right">
            <div className="scroll_wrapper">
              <span />
              <div className="img_wrapper">
                <img
                  src={require("../../assets/img/portfolio/colorex_entire_page.png")}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="container-fluid colorex_section_4">
        <div className="row row_1">
          <div className="col-md-6 col-image left">
            <img
              src={require("../../assets/img/portfolio/colorex_company.png")}
              alt=""
            />
          </div>
          <div className="col-md-6 col-image right">
            <img
              src={require("../../assets/img/portfolio/colorex_map.png")}
              alt=""
            />
          </div>
        </div>
      </section>
      <PortfolioNav data={nav} locales={locales[lang]} />
    </Layout>
  );
};
export default Colorex;
